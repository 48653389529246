import store from '@/app/app-state';
import { FETCH_ORDERS } from '@/app/clients/shared/state/types/action_types';
import { COURIER_STORE_MODULE_NAME } from '@/app/couriers/courierStoreModule';
import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';

export function useCurrentOrderList() {
  const date = ref('');
  const today = moment().format('YYYY-MM-DD');

  let focusCourierOrder = 0;

  const couriers = computed(
    () => store.getters[`${COURIER_STORE_MODULE_NAME}/autocompleteCouriers`]
  );

  const bindCourierToOrder = (order_id, courier_id) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/bindCourierToOrder`, { order_id, courier_id });
  };

  const unbindCourierToOrder = (orderId, courierId) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/unbindCourierToOrder`, { courierId, orderId });
  };

  const fetchOrders = (date) => {
    store.dispatch(FETCH_ORDERS, { date });
  };

  const changeCourier = (courierId, orderId) => {
    unbindCourierToOrder(orderId, focusCourierOrder);
    bindCourierToOrder(orderId, courierId);
  };

  const focusCourier = (e) => {
    if (e.target.value) {
      const label = e.target.value;
      const { value } = couriers.value.find((c) => c.label === label);
      focusCourierOrder = value;
    }
  };

  const fetchCouriers = () => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/fetchCouriers`);
  };

  watch(date, (val) => {
    fetchOrders(val || today);
  });

  fetchOrders(today);
  fetchCouriers();

  const data = computed(() =>
    store.state.clientsState.clientsData.orders_cal
      ? store.state.clientsState.clientsData.orders_cal.data
      : []
  );

  const formatterDate = (_, row, value) => moment(value).locale('ru').format('ll');

  return { data, formatterDate, date, couriers, changeCourier, focusCourier };
}
