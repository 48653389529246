<template>
  <div class="dashboard">
    <el-tabs v-model="activeName">
      <el-tab-pane v-if="isAvailable" label="Статистика" name="staticstic">
        <el-row class="mt-4" :gutter="20">
          <el-col v-if="statistics" :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Продажи</span>
              </div>
              <div v-for="sale in statistics.sales" :key="`sale-${sale.branch}`" class="text item">
                {{ sale.branch }} - {{ sale.sum }} ₽ ({{ sale.count }})
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card v-if="statistics" class="box-card">
              <div slot="header" class="clearfix">
                <span>Заказы на кухне</span>
              </div>
              <div
                v-for="kitchen in statistics.kitchens"
                :key="`kitchens-${kitchen.branch}`"
                class="text item"
              >
                {{ kitchen.branch }} - {{ kitchen.sum }} ₽ ({{ kitchen.count }})
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card v-if="statistics" class="box-card">
              <div slot="header" class="clearfix">
                <span>Заказы на доставке</span>
              </div>
              <div v-for="d in statistics.delivery" :key="`dilevery-${d.branch}`" class="text item">
                {{ d.branch }} - {{ d.sum }} ₽ ({{ d.count }})
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane v-if="isManager || isAdmin" label="Задачи" name="staticstic">
        <manager-board />
      </el-tab-pane>
      <el-tab-pane v-if="isAvailable || isManager || isAdmin" label="Текущие заказы" name="orders">
        <current-order-list />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ManagerBoard from '@/app/manager/manager.vue';
import { FETCH_ORDERS } from '@/app/clients/shared/state/types/action_types';
import moment from 'moment';
import CurrentOrderList from './current-order-list/current-order-list.vue';

export default {
  name: 'Dashboard',
  components: { ManagerBoard, CurrentOrderList },
  data() {
    return {
      statistics: null,
      activeName: 'staticstic',
      date: '',
    };
  },
  computed: {
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'administrator'].includes(state.authState.authState.role),
      isAdmin: (state) => ['admin'].includes(state.authState.authState.role),
      isManager: (state) => ['manager'].includes(state.authState.authState.role),
      orders: (state) => state.clientsState.clientsData.orders_cal,
    }),
  },
  watch: {
    async date(value) {
      if (!value) {
        await this.fetchOrders({ date: moment().format('YYYY-MM-DD') });
        return;
      }
      await this.fetchOrders({ date: value });
    },
  },
  async created() {
    this.statistics = await this.$store.dispatch('FETCH_STATISTICS');
  },
  async mounted() {
    await this.fetchOrders({ date: moment().format('YYYY-MM-DD') });
  },
  methods: {
    ...mapActions({
      fetchOrders: FETCH_ORDERS,
    }),
  },
};
</script>
