<template>
  <div>
    <el-date-picker
      v-model="date"
      type="date"
      placeholder="Фильтр по дате"
      class="mb-2 mt-2"
      value-format="yyyy-MM-dd"
      :picker-options="{ firstDayOfWeek: 1 }"
      size="small"
    />
    <el-table :data="data" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.position" style="width: 100%">
            <el-table-column label="Блюдо" prop="title" />
            <el-table-column label="Кол-во" prop="quantity" />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="Дата" prop="date" :formatter="formatterDate" />
      <el-table-column label="Клиент" prop="client">
        <template slot-scope="{ row }">
          <router-link :to="`/client/${row.client_id}/info`">
            {{ row.client }}
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { useCurrentOrderList } from './useCurrentOrderList';

export default {
  name: 'CurrentOrderList',
  setup() {
    const { data, formatterDate, date } = useCurrentOrderList();
    return { data, formatterDate, date };
  },
};
</script>
